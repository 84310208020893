import sanitize from 'light-sanitize-html';
import { qs } from '../dom-helpers';

class Video {
  static get VIDEO_CLASS() { return 'video'; }

  static get VIDEO_FRAME_CLASS() { return 'video__media'; }

  static get VIDEO_PLAYING_CLASS() { return 'video--playing'; }

  constructor() {
    this._run();
  }

  _run() {
    this._initEventListeners();
  }

  _initEventListeners() {
    document.addEventListener('click', (e) => this._handleVideoClick(e));
  }

  _handleVideoClick(e) {
    const element = e.target;
    const video = element.closest(`.${Video.VIDEO_CLASS}`);

    if (!video) return;

    e.preventDefault();

    Video.play(video);
  }

  static play(video) {
    const link = qs('.video__link', video);
    const button = qs('.video__play-button', video);
    const existingIframe = qs(`iframe.${Video.VIDEO_FRAME_CLASS}`, video);
    const title = video.dataset.title;
    const id = video.dataset.id;

    if (existingIframe) {
      return false;
    }

    const iframe = Video._createIframe(id, title);

    link.remove();
    button.remove();

    video.appendChild(iframe);

    iframe.focus();
  }

  static _createIframe(id, title) {
    const iframe = document.createElement('iframe');

    iframe.setAttribute('aria-label', sanitize(title));
    iframe.setAttribute('frameBorder', '0');
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
    );
    iframe.setAttribute('allowfullscreen', 'true');
    iframe.setAttribute(
      'src',
      `https://www.youtube-nocookie.com/embed/${sanitize(id)}?rel=0&showInfo=0&autoplay=1`,
    );

    iframe.classList.add(Video.VIDEO_FRAME_CLASS);

    return iframe;
  }

  static stop(video) {
    const frame = video.querySelector(`iframe.${Video.VIDEO_FRAME_CLASS}`);

    if (!frame) {
      return false;
    }

    frame.src = frame.src.replace('autoplay=1', 'autoplay=0');
  }
}

export default Video;
