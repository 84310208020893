/* global mainState */

class Abstract {
  constructor() {
    if (this.constructor === Abstract) {
      throw new TypeError('Can not construct abstract class.');
    }

    this.isHome = !!document.querySelector('.home');
    this.isCart = !!document.querySelector('.woocommerce-cart');
    this.isShop = !!document.querySelector('.woocommerce-shop');
    this.isSingle = !!document.querySelector('.single-product');
    this.isCheckout = !!document.querySelector('.woocommerce-checkout form.checkout');

    this._htmlClasses = {
      disabled: 'disabled',
      visible: 'visible',
      minicart: 'mini-cart',
      minicartIconActive: 'main-header__cart-link--open-cart',
      itemTabHeading: 'one-item__tab-btn',
      activeItemTabHeading: 'one-item__tab-btn--active-tab',
      itemTabInfo: 'one-item__tab-info',
      activeItemTabInfo: 'one-item__tab-info--active',
      invalidField: 'form__input--error',
    };

    this._blockUISettings = {
      message: null,
      baseZ: 99999999,
      overlayCSS: {
        background: '#f3f6f9',
        opacity: 0.6,
      },
      css: {
        padding: '20px',
        zindex: '9999999',
        textAlign: 'center',
        color: '#555',
        border: '3px solid #aaa',
        backgroundColor: '#fff',
        cursor: 'wait',
        lineHeight: '24px',
      },
    };

    this.ORDER_COMMENTS_KEY = 'orderComments';
    this.DELIVERY_DATE_KEY = 'deliveryDate';
  }

  static isFunction(f) {
    return typeof f === 'function';
  }

  static findClassNameAndRemove(className, parentNode) {
    const node = parentNode || document;

    const el = node.querySelector(`.${className}`);

    if (el) {
      el.classList.remove(className);
    }
  }

  static updateCart() {
    jQuery('[name="update_cart"]').prop('disabled', false).trigger('click');
  }

  static refreshFragments() {
    jQuery(document.body).trigger('wc_fragment_refresh');
  }

  getBlockSettings() {
    return this._blockUISettings;
  }

  getHTMLClasses() {
    return this._htmlClasses;
  }

  static getState() {
    return mainState;
  }

  static getI18n() {
    return mainState.i18n;
  }

  static fadeIn(node) {
    return jQuery(node).fadeIn();
  }

  static fadeOut(node) {
    return jQuery(node).fadeOut();
  }

  static formDataToObject(formData) {
    const result = {};

    formData.forEach((value, key) => {
      if (!Reflect.has(result, key)) {
        result[key] = value;
        return;
      }

      if (!Array.isArray(result[key])) {
        result[key] = [result[key]];
      }

      result[key].push(value);
    });

    return result;
  }
}

export default Abstract;
