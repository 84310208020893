import { qs, qsAll } from '../dom-helpers';
import Video from './Video';

class Modal {
  constructor(options = {}) {
    this.overlay = options.overlay || null;
    this.modal = options.modal || null;

    if (!this.overlay || !this.modal) {
      console.error('Overlay or modal element has not provided');  // eslint-disable-line
      return;
    }
    this.closeButton = qs('.close-btn', this.modal);

    this.focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    this._run();
  }

  _run() {
    this.closeButton.addEventListener('click', () => this.close());

    this.overlay.addEventListener('click', (e) => {
      if (!e.target.classList.contains('modal-overlay')) {
        return;
      }

      this.close();
    });

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });

    this._enableFocusTrap();
  }

  open() {
    this.overlay.classList.remove('hidden');
    this.modal.focus();
  }

  close() {
    this.overlay.classList.add('hidden');

    const video = qs(`.${Video.VIDEO_CLASS}`, this.modal);
    const videoFrame = qs(`.${Video.VIDEO_FRAME_CLASS}`, this.modal);

    if (video && videoFrame) {
      Video.stop(video);
    }
  }

  _enableFocusTrap() {
    const firstFocusableElement = qsAll(this.focusableElements, this.overlay)[0];
    const focusableContent = qsAll(this.focusableElements, this.overlay);
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    document.addEventListener('keydown', (e) => {
      const isTabPressed = e.key === 'Tab';

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    });

    firstFocusableElement.focus();
  }
}

export default Modal;
