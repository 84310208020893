import { qs } from '../dom-helpers';
import __ from './i18n';

class About {
  constructor() {
    this.readMoreButton = qs('.read-more-button');
    this.descriptionContainer = qs('.about__desc-box');
    this.toBottom = qs('.to-next-screen');
    this.target = qs('.advantages');

    this.isExpanded = false;

    this._run();
  }

  _run() {
    this._initEventListeners();
  }

  _initEventListeners() {
    if (this.readMoreButton) {
      this.readMoreButton.addEventListener('click', () => this._expandText());
    }

    if (this.toBottom) {
      this.toBottom.addEventListener('click', () => this._smoothScroll());
    }
  }

  _expandText() {
    if (this.descriptionContainer.classList.contains('about__desc-box--opened')) {
      this.descriptionContainer.classList.remove('about__desc-box--opened');
      this.isExpanded = false;
    } else {
      this.descriptionContainer.classList.add('about__desc-box--opened');
      this.isExpanded = true;
    }

    this._changeButtonText();
  }

  _changeButtonText() {
    if (this.isExpanded) {
      this.readMoreButton.innerText = __('Read less');
    } else {
      this.readMoreButton.innerText = __('Read more');
    }
  }

  _smoothScroll() {
    if (this.target) {
      this.target.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
}

export default About;
