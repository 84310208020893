import sanitize from 'light-sanitize-html';
import Abstract from './Abstract';

const __ = (string) => {
  const translations = Abstract.getI18n();

  if (translations[string]) {
    return sanitize(translations[string]);
  }

  return string;
};

export default __;
