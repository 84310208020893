import './polyfill';

import { qs, qsAll } from './dom-helpers';

import VideosCarousel from './classes/VideosCarousel';
import Video from './classes/Video';
import CTAButtons from './classes/CTAButtons';
import GoogleMaps from './classes/GoogleMaps';
import VideoGallery from './classes/VideoGallery';
import About from './classes/About';

class Main {
  constructor() {
    this._run();
  }

  _run() {
    try {
      this._registerGlobalObjects();

      new CTAButtons();
      new VideosCarousel();
      new Video();
      new GoogleMaps(qs('.contacts__gmaps'), 10).initMap();
      new VideoGallery();
      new About();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerGlobalObjects() {
    window.qs = qs;
    window.qsAll = qsAll;
  }
}

document.addEventListener('DOMContentLoaded', () => new Main());
