import { qs, qsAll } from '../dom-helpers';

class VideoGallery {
  constructor() {
    this.showMoreButton = qs('.show-more-button');

    this._run();
  }

  _run() {
    this._initEventListeners();
  }

  _initEventListeners() {
    if (this.showMoreButton) {
      this.showMoreButton.addEventListener('click', () => this._showMoreVideos());
    }
  }

  _showMoreVideos() {
    this.hiddenGalleryVideos = qsAll('.clients__videoblog-list-item.hidden');

    if (!this.hiddenGalleryVideos) {
      return;
    }

    this.hiddenGalleryVideos.forEach((video, i) => {
      if (i > 1) {
        return;
      }

      video.classList.remove('hidden');
    });

    if (this.hiddenGalleryVideos.length <= 2) {
      this._removeMoreButton();
    }
  }

  _removeMoreButton() {
    this.showMoreButton.remove();
  }
}

export default VideoGallery;
