import sanitize from 'light-sanitize-html';
import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';
import { qs } from '../dom-helpers';
import Abstract from './Abstract';
import __ from './i18n';
import playButtonIcon from './SVG';
import Video from './Video';

class VideosCarousel {
  constructor() {
    if (!qs('.clients__carousel')) return;

    this.slider = null;

    this.videoSlidesContainer = qs('.clients__carousel-list');
    this.videoPrevSlideBtn = qs('.clients__carousel-button--previous');
    this.videoNextSlideBtn = qs('.clients__carousel-button--next');
    this.slideContent = qs('.clients__single-video');

    this.ACTIVE_SLIDE_CLASS = 'clients__carousel-list-item--active';
    this.PLAY_BUTTON_CLASS = 'clients__button';

    this._run();
  }

  _run() {
    this._initSlider();
    this._initEventListeners();
  }

  _initEventListeners() {
    if (this.videoSlidesContainer) {
      this.videoSlidesContainer.addEventListener('click', (e) => this._handleSlideClick(e));
      this.slideContent.addEventListener('click', (e) => this._handlePlayButtonClick(e));
    }
  }

  _handlePlayButtonClick(e) {
    const element = e.target;
    const isButton = element.closest(`.${this.PLAY_BUTTON_CLASS}`);

    if (!isButton) return;

    const video = qs(`.${Video.VIDEO_CLASS}`, this.slideContent);

    if (video) {
      Video.play(video);
    }
  }

  _handleSlideClick(e) {
    const element = e.target;
    const parent = element.closest('.clients__carousel-list-item');

    if (!parent) return;

    Abstract.findClassNameAndRemove(this.ACTIVE_SLIDE_CLASS, this.videoSlidesContainer);
    parent.classList.add(this.ACTIVE_SLIDE_CLASS);

    this._updateSlideData(parent.dataset);
  }

  _updateSlideData(slideData) {
    this.slideContent.innerHTML = `
       <div class="clients__single-video-preview">
         <div class="clients__single-video-box video"
            data-id="${sanitize(slideData.videoId)}"
            data-title="${sanitize(slideData.name)}">
           <a href="${sanitize(slideData.videoLink)}"
              class="clients__single-video-link video__link">
             <img src="${sanitize(slideData.videoBg)}"
                  alt=""
                  role="presentation">
           </a>
  
           <button class="clients__single-video-play-btn video__play-button"
                   aria-label="${__('Play video')}">
             ${playButtonIcon}
           </button>
         </div>
         
         <time class="clients__single-video-time">${sanitize(slideData.videoDutation)}</time>
       </div>
       
       <div class="clients__single-video-desc">
         <h3 class="third-title clients__client-name">
           ${sanitize(slideData.name)}
         </h3>
       
         <div class="clients__desc-box">
           <p>${sanitize(slideData.description)}</p>
         </div>
       
         <button class="main-button clients__button">
           <span>
             ${playButtonIcon}
           </span>
       
           <span>${__('See video testimonial')}</span>
         </button>
       </div>
    `;
  }

  _initSlider() {
    const _this = this;

    this.slider = tns({
      container: _this.videoSlidesContainer,
      items: 3,
      responsive: {
        767: {
          items: 6,
        },
      },
      nav: false,
      mouseDrag: true,
      disable: false,
      prevButton: _this.videoPrevSlideBtn,
      nextButton: _this.videoNextSlideBtn,
    });
  }

  stop() {
    this.slider.pause();
  }

  resume() {
    this.slider.play();
  }
}

export default VideosCarousel;
