/* global google */
import Abstract from './Abstract';

class GoogleMaps {
  constructor(mapWrapper, scrollY) {
    this.mapWrapper = mapWrapper;
    this.initScrollY = scrollY;

    this.gMapsApiKey = Abstract.getState().googleMaps.apiKey;
    this.zoom = Abstract.getState().googleMaps.zoom;
    this.mapCenter = Abstract.getState().googleMaps.mapCenter;
    this.markers = Abstract.getState().googleMaps.markers;
  }

  initMap() {
    if (!this.gMapsApiKey || !this.mapWrapper) return;

    window.initMap = () => {
      const map = new google.maps.Map(
        this.mapWrapper, {
          center: new google.maps.LatLng(this.mapCenter.latitude, this.mapCenter.longitude),
          zoom: this.zoom,
        },
      );

      if (this.markers.length) {
        this.markers.forEach((marker) => {
          new google.maps.Marker({
            position: new google.maps.LatLng(marker.latitude, marker.longitude),
            icon: marker.icon ? marker.icon : undefined,
            map,
          });
        });
      }
    };

    const renderMap = () => {
      if (window.scrollY >= this.initScrollY) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.gMapsApiKey}&callback=window.initMap`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window.removeEventListener('scroll', renderMap);
      }
    };

    window.addEventListener('scroll', renderMap);
    window.addEventListener('DOMContentLoaded', renderMap);
  }
}

export default GoogleMaps;
