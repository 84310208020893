import { qs } from '../dom-helpers';
import Modal from './Modal';

class CTAButtons {
  constructor() {
    this.contactsModal = new Modal({
      overlay: qs('.modal-overlay--callback'),
      modal: qs('.modal--callback'),
    });
    this.introductionVideoModal = new Modal({
      overlay: qs('.modal-overlay--video'),
      modal: qs('.modal--video'),
    });

    this.callbackButton = qs('.callback');
    this.callbackAbout = qs('.about__button');
    this.openIntroductionVideoModalButton = qs('.button-pulse');

    this._run();
  }

  _run() {
    if (this.callbackButton) {
      this.callbackButton.addEventListener('click', () => this.contactsModal.open());
    }

    if (this.callbackAbout) {
      this.callbackAbout.addEventListener('click', () => this.contactsModal.open());
    }

    if (this.openIntroductionVideoModalButton) {
      this.openIntroductionVideoModalButton.addEventListener('click', () => this.introductionVideoModal.open());
    }
  }
}

export default CTAButtons;
